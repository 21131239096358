// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archive-js": () => import("./../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-discover-js": () => import("./../src/pages/discover.js" /* webpackChunkName: "component---src-pages-discover-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-js": () => import("./../src/pages/new.js" /* webpackChunkName: "component---src-pages-new-js" */),
  "component---src-pages-submit-js": () => import("./../src/pages/submit.js" /* webpackChunkName: "component---src-pages-submit-js" */),
  "component---src-templates-artist-js": () => import("./../src/templates/artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-song-js": () => import("./../src/templates/song.js" /* webpackChunkName: "component---src-templates-song-js" */),
  "component---src-templates-tag-js": () => import("./../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

